import React from 'react';
import DevOPS from '../assets/devopicon.svg'; 
import AI from '../assets/iaicon.svg'; 
import MATH from '../assets/piicion.svg'; 
import ARCHITECTURE from '../assets/archIcon.svg'; 
import WEBAPP from '../assets/webappIcon.svg'; 
import SECURITY from '../assets/securicon.svg'; 
import {useTranslation} from 'react-i18next'



const Expertise = () => {
 const { t, i18n } = useTranslation();
    return (
    <div className="section">
    <div className="description">
      <h1 className="section-title"><span className="firstWord">{t('expertise.Our')} </span> {t('expertise.Expertise')}</h1>
      <p className="flex-description">
        {t('expertise.Description')}
      </p>
      </div>
      <div className="flex-items-container">
        <div className="flex-item-element">
          <div className="image-container">
            <img src={DevOPS} alt="DevOps icon" className="icon-image"/>
          </div>
          <div className="description-text">
                    {t('expertise.Devops1')}<span className="firstWord"> {t('expertise.DevopsImportant')}</span> {t('expertise.Devops2')}
          </div>
        </div>
        
        <div className="flex-item-element">
          <div className="image-container">
            <img src={AI} alt="ai icon" className="icon-image"/>
          </div>
          <div className="description-text">{t('expertise.AI1')} <span className="firstWord"> {t('expertise.AIImportant')} </span> {t('expertise.AI2')}
          </div>
        </div>
        
        <div className="flex-item-element">
          <div className="image-container">
            <img src={MATH} alt="optimization icon" className="icon-image"/>
          </div>
          <div className="description-text">{t('expertise.Optimization1')}<span className="firstWord">        {t('expertise.OptimizationImportant')}</span> {t('expertise.Optimization2')}
          </div>
          </div>
        
        <div className="flex-item-element">
          <div className="image-container">
            <img src={ARCHITECTURE} alt="architecture icon" className="icon-image"/>
          </div>
          <div className="description-text">{t('expertise.Architecture1')}<span className="firstWord">        {t('expertise.ArchitectureImportant')}</span> {t('expertise.Architecture2')}
          </div>
          </div>
        
        <div className="flex-item-element">
          <div className="image-container">
            <img src={WEBAPP} alt="web app icon" className="icon-image"/>
          </div>
          <div className="description-text">{t('expertise.WebApp1')}<span className="firstWord">       {t('expertise.WebAppImportant')}</span> {t('expertise.WebApp2')}
          </div>
          </div>
        
        <div className="flex-item-element">
          <div className="image-container">
            <img src={SECURITY} alt="security icon" className="icon-image"/>
          </div>
          <div className="description-text">{t('expertise.Security1')}<span className="firstWord">        {t('expertise.SecurityImportant')}</span> {t('expertise.Security2')}
          </div>
          </div>
      </div>
    </div>
  );
};

export default Expertise;

