import React from 'react';
import {useTranslation} from 'react-i18next'

const Contact = () => {
  const { t, i18n } = useTranslation();
return(
  <div className="section" style={{ textAlign: 'center', padding: '50px 0' }}>
<a href="mailto:geoffrey.glangine@gmail.com" className="contact-button">{t('contact.contact')}</a></div>
);
}

export default Contact;
