import React from 'react';
import Photo from '../assets/photo.jpg'; 
import {useTranslation} from 'react-i18next'



const About = () => {
  const { t, i18n } = useTranslation();
  return(
  <div className="section">
    <div className="description">
      <h1 className="section-title"><span className="firstWord">{t('about.about1')} {t('about.about2')}</span></h1>
      <p className="flex-description">
      {t('about.description')}
      </p>
      </div>
      <div className="flex-items-container">
        <div className="text-content">
          <h1>{t('about.president')}</h1>
          <p>
            {t('about.presidentDesc')}
          </p>
        </div>
        <div className="image-content">
          <div className="image-wrapper">
          <div className="test">
          <img src={Photo} alt="Picture of Geoffrey"/>
          </div>
          </div>
        </div>
      </div>
    </div>
      );
}

export default About;

