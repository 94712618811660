import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link, Element, Events, animateScroll as scroll } from 'react-scroll';
import Expertise from './components/expertise.js'
import About from './components/about.js'
import Contact from './components/contact.js'
import Banner from './components/banner.js'
import NavBar from './components/navbar.js'
import './App.css';
import './i18n';
import {useTranslation} from 'react-i18next';
import Logo from './assets/logo.svg'; 

const App = () => {    
  // Initialize react-scroll events
  React.useEffect(() => {
    Events.scrollEvent.register('begin', () => console.log('Scrolling begins!'));
    Events.scrollEvent.register('end', () => console.log('Scrolling ends!'));

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <NavBar />

        <Element name="home" className="element">
            <Banner />
        </Element>
        <Element name="expertise" className="element">
          <Expertise />
        </Element>
        <Element name="about" className="element">
          <About />
        </Element>
        <Element name="contact" className="element">
          <Contact />
        </Element>
      </div>
    </Router>
  );
};


export default App;
