import React from 'react';
import {useTranslation} from 'react-i18next'
import {useState, useEffect} from 'react';

function generateNumbersGaussian(mean, sigma, min, max, count) {
  const numbers = [];
    let u1 = 0, u2 = 0;
    while (u1 === 0) u1 = Math.random();
    while (u2 === 0) u2 = Math.random();
    let R = Math.sqrt(-2.0 * Math.log(u1));
    let theta = 2.0 * Math.PI * u2;
    let z0 = R * Math.cos(theta);
    let number = mean + z0 * sigma;
    return number;
}

let mean = 50;
let sigma = 100; // Adjust this value to increase/decrease the spread around 50
let min = 50; // Adjust if needed to ensure no number goes below this
let max = 300; // Adjust if needed to ensure no number goes above this
let count = 15;
let numbers = generateNumbersGaussian(mean, sigma, min, max, count);

const Banner = () => {
const { t, i18n } = useTranslation();
  const [fullText, setFullText] = useState(t('banner.text'));
  const [typedText, setTypedText] = useState('');
  const [index, setIndex] = useState(0);
useEffect(() => {
    setIndex(0);
    setTypedText('');
    setFullText(t('banner.text')); // Get the new translated text
  }, [i18n.language]); // Reset everything when the language changes


  useEffect(() => {
    if (index < fullText.length) {

      const charDelay = generateNumbersGaussian(mean,sigma,min,max,count); 

      const timerId = setTimeout(() => {
        setTypedText(typedText + fullText.charAt(index));
        setIndex(index + 1);
      }, charDelay);

      return () => clearTimeout(timerId);
    }
  }, [index, typedText, fullText]);

  return (
    <div className="section" style={{ backgroundColor: 'black' }}>
      <p className="gradient-text">
        {typedText}
        <span className="cursor">_</span>
      </p>
    </div>
  );
};

export default Banner;

