import React from 'react';
import {useTranslation} from 'react-i18next'
import {Link} from 'react-scroll'
import Logo from '../assets/logo.svg'; 
import { useState, useCallback } from 'react';
import { slide as Menu } from 'react-burger-menu';
import useWindowSize from './useWindowsSize.js';

const Navbar = () => {
    
    const { t, i18n } = useTranslation();
    const changeLanguage = () => {
        const currentLanguage=i18n.language;
        const newLanguage=currentLanguage === 'en'?'fr':'en';
        i18n.changeLanguage(newLanguage);
    }
    const size = useWindowSize(); 
    
    const [menuOpen, setMenuOpen] = useState(false);

    const handleStateChange = useCallback((state) => {
    setMenuOpen(state.isOpen);
    }, []);

    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, []);

    const toggleMenu = useCallback(() => {
        setMenuOpen(prevState => !prevState);
    }, []);
    
    return (
         <>
    {size.width > 900 ? (
        <nav className="navbar">
          <img src={Logo} alt="optimalit" className="logo"/>
      <Link activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-50}>
        {t('nav.home')}
      </Link>
          <Link activeClass="active" to="expertise" spy={true} smooth={true} duration={500} offset={-50}>
            {t('nav.expertise')}
          </Link>
          <Link activeClass="active" to="about" spy={true} smooth={true} duration={500} offset={-50}>
            {t('nav.about')}
          </Link>
          <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-50}>
            {t('nav.contact')}
          </Link>
            <button className="nav-item" onClick={changeLanguage}>
            <span style={{ color: i18n.language === 'en' ? '#c19cff' : 'inherit' }}>EN </span>
            |
            <span style={{ color: i18n.language === 'fr' ? '#c19cff' : 'inherit' }}> FR</span>
            </button>

        </nav>) : (
        
        <nav className="navbar"> 
      <Menu isOpen={menuOpen}
        onStateChange={handleStateChange}
        width = '100%'>
        <Link activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-50} onClick={closeMenu}>
        {t('nav.home')}
      </Link>
          <Link activeClass="active" to="expertise" spy={true} smooth={true} duration={500} offset={-50} onClick={closeMenu}>
            {t('nav.expertise')}
          </Link>
          <Link activeClass="active" to="about" spy={true} smooth={true} duration={500} offset={-50} onClick={closeMenu}>
            {t('nav.about')}
          </Link>
          <Link activeClass="active" to="contact" spy={true} smooth={true} duration={500} offset={-50} onClick={closeMenu}>
            {t('nav.contact')}
          </Link>
            <button className="nav-item" onClick={changeLanguage}>
            <span style={{ color: i18n.language === 'en' ? '#c19cff' : 'inherit' }}>EN </span>
            |
            <span style={{ color: i18n.language === 'fr' ? '#c19cff' : 'inherit' }}> FR</span>
            </button>
      </Menu>
      <img src={Logo} alt="optimalit" className="logo"/>
      </nav>)}
      </>
    );
    
    
    
};

export default Navbar;
